import i18n from "../resources/I18n";
import Content from "../components/APRHN/Content";
import { INavigationParcours } from "../util/Navigation";

interface IPageAPRHNProps {
  questionnaireContexte?: any;
  cookieParcoursAssurance: INavigationParcours;
}

function PageAPRHN(props: IPageAPRHNProps) {
  return (
    <div data-testid="page-aprhn" className="App">
      <span
        id="chargement-termine"
        data-testid="chargement-termine"
        className={"dsd-sr-only"}
        tabIndex={-1}
        aria-hidden={"true"}
      >
        {i18n.t("assurabilite:sr-only-chargement-termine")}
      </span>
      <Content cookieParcoursAssurance={props.cookieParcoursAssurance} />
    </div>
  );
}

export default PageAPRHN;
