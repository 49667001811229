import Configuration from "../../Configuration";
import axios from "axios";
import * as modeles from "../../models/";
import axiosRetry from "axios-retry";
import { AuthentificationService } from "@oel/js-authentification";

/**
 * @module ServiceAssurabiliteBFF
 */
class ServiceAssurabiliteBFF {
  constructor() {
    axios.defaults.timeout = 30000;

    axiosRetry(axios, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return !error.response || error.response.status === 408 || error.response.status >= 500;
      },
    });
  }

  /**
   * @method preparerDemarrerPreAssurabilite
   * Permet de préparer l'objet pour l'envoyer à la valadation d'admissibilité
   * @param {string} idSouscription id de souscription reçu du cookie
   * @param {string} idAssure id de l'assuré reçu du cookie
   * @param {string} langue langue reçu du cookie
   */
  preparerDemarrerPreAssurabilite(idSouscription: string, idAssure: string, langue: string): modeles.DemandeAssurance {
    return {
      idSouscription: idSouscription,
      idAssure: idAssure,
      langue: langue,
    } as modeles.DemandeAssurance;
  }

  async effectuerAppelPostDemarrerPreAssurabilite(
    idSouscription: string,
    idAssure: string,
    langue: string,
    functionSucces: Function,
    functionErreur: Function
  ) {
    //On ajoute le token obtenu de Auth0 à l'entêtes de nos appels axios si on est pas en local
    if (Configuration.obtenirConfigurations().authenfication) {
      try {
        const token = await AuthentificationService.getToken();
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      } catch {
        functionErreur();
      }
    }
    axios
      .post(
        `${Configuration.obtenirConfigurations().urlAssurabiliteBFF}/`,
        this.preparerDemarrerPreAssurabilite(idSouscription, idAssure, langue)
      )
      .then(
        (response) => functionSucces(response),
        (error) => functionErreur(error)
      );
  }

  /**
   * @method preparerRepondreQuestion
   * Permet de préparer l'objet pour l'envoyer à la valadation d'admissibilité
   * @param {string} idQuestion id de la question que le client a repondu
   * @param {number} step step de la question
   * @param {string} reponse reponse du client
   */
  preparerRepondreQuestion(idQuestion: string, step: number, reponse: string): modeles.ReponseQuestion {
    return {
      idQuestion: idQuestion,
      step: step,
      reponse: reponse,
    } as modeles.ReponseQuestion;
  }

  /**
   * @method effectuerAppelPostRepondreQuestionPreassurabilite
   * Fait l'appel au BFF
   * @param {string} idAssurabilite id de l'assurabilité du client
   * @param {string} idQuestion id de la question que le client a repondu
   * @param {number} step step de la question
   * @param {string} reponse reponse du client
   * @param {Function} functionSucces Fonction à appeler lors d'un succès
   * @param {Function} functionErreur Fonction à appeler lors d'une erreur
   */
  async effectuerAppelPostRepondreQuestionPreassurabilite(
    idAssurabilite: string,
    idQuestion: string,
    step: number,
    reponse: string,
    functionSucces: Function,
    functionErreur: Function
  ) {
    const url = `${
      Configuration.obtenirConfigurations().urlAssurabiliteBFF
    }/${idAssurabilite}/RepondreQuestionPreassurabilite`;
    axios.post(url, this.preparerRepondreQuestion(idQuestion, step, reponse)).then(
      (response) => functionSucces(response),
      (error) => functionErreur(error)
    );
  }
}

export default ServiceAssurabiliteBFF;
