import Cookies from "universal-cookie";
import { INavigationParcours } from "./Navigation";
import { Cookie } from "./Constantes";

// paramètre pour tests unitaires
export function setCookieParamsMock(processEnvironnement: string = process.env.NODE_ENV) {
  const cookie = new Cookies();

  if (processEnvironnement !== "test") {
    const query = new URLSearchParams(window.location.search);

    let parcoursAssuranceCookie: INavigationParcours = {
      idSouscription: query.get("idSouscription"),
      idAssure: query.get("idAssure"),
      langue: query.get("langue"),
      idCallback: "33d2a6c7-f4de-4dc8-8cea-199c51abce68",
    };

    if (
      parcoursAssuranceCookie.idSouscription != null &&
      parcoursAssuranceCookie.idAssure != null &&
      parcoursAssuranceCookie.langue != null
      ) {
      cookie.set(Cookie.ParcoursAssurance, parcoursAssuranceCookie, { domain: window.location.origin.includes("localhost") ? 'localhost' : '.desjardins.com' });

      let url = window.location.origin + window.location.pathname;
      window.location.href = url;
    }
  }
}
