import Cookies from "universal-cookie";
import i18n from "../resources/I18n";
import { Cookie } from "./Constantes";
import { INavigationParcours } from "./Navigation";

export enum Langues {
  FR = "fr",
  EN = "en",
}
const cookie = new Cookies();

export function getCookie(nomCookie: string) {
  return cookie.get(nomCookie);
}

export function obtenirLangueCookie() {
  let langue = Langues.FR;

  let cookieParcoursAssurance: INavigationParcours = cookie?.get(Cookie.ParcoursAssurance);

  if (cookieParcoursAssurance !== undefined) {
    if (cookieParcoursAssurance.langue === Langues.EN) {
      langue = Langues.EN;
    }
  } else if (
    window.location.pathname === i18n.t("url-erreur-technique", { lng: "en" }) ||
    window.location.pathname === i18n.t("url-erreur-fonctionnelle", { lng: "en" })
  ) {
    langue = Langues.EN;
  }

  return langue;
}
