import Content from "../components/JAMPP/Content";
import { INavigationParcours } from "../util/Navigation";

interface IPageJAMPPProps {
  parcours: string;
  cookieParcoursAssurance: INavigationParcours;
}

function PageJAMPP(props: IPageJAMPPProps) {
  return <Content cookieParcoursAssurance={props.cookieParcoursAssurance} parcours={props.parcours} />;
}

export default PageJAMPP;
