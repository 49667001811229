import i18n from "../../resources/I18n";
import { Component } from "react";
import "./Content.css";
import Questionnaire from "./Questionnaire";
import ServiceAssurabiliteBFF from "../utils/ServiceAssurabiliteBFF";
import { INavigationParcours, obtenirNavigationUrl } from "../../util/Navigation";
import { ErreurHTTP } from "../../util/Constantes";
import { InformationsPreAssurabilite } from "../../models/informations-pre-assurabilite";
import SpanAlerte from "../APRHN/SpanAlerte";
import TexteHautQuestionnaire from "./TexteHautQuestionnaire";
import { ReponsePreAssurabilite } from "../../models";
import Boutons from "./Boutons";
import QuestionsSommaireErreurs from "./QuestionsSommaireErreurs";
import { QuestionnaireContext } from "../utils/QuestionnaireContext";
import ClickToCall from "./ClickToCall";
import * as TYPE_PARCOURS from "../../constants/typeParcours";
import MenuEtape from "./MenuEtape";
import StepIndicator from "./StepIndicator";
import * as CONFIG_GABARIT_GLOBAL from "../../GabaritGlobalConfig";
import ModalLoadingJAMPP from "./ModalLoadingJAMPP";
import { CybermetrieJAMPPContext } from "../utils/CybermetrieJAMPPContext";
import { CybermetrieParamsJAMPP } from "../../models/CybermetrieParamsJAMPP";
import { CybermetriesJAMPP } from "../../util/CybermetrieJAMPP";

interface IContentProps {
  parcours: string;
  cookieParcoursAssurance: INavigationParcours;
}

interface IContentState {
  informationsPreAssurabilite: InformationsPreAssurabilite;
  erreurStatut: number;
  enChargement: boolean;
  enChargementQuestion: boolean;
  stateQuestionnaireSoumis: boolean;
  questionStepCourant: number;
  showBtnContinuer: boolean;
  modaleEnregistrerOuvert: boolean;
  declencheurModaleEnregistrer: string;
}

/**
 * @module Content
 * @extends Component
 */
class Content extends Component<IContentProps, IContentState> {
  ServiceAssurabiliteBFF: ServiceAssurabiliteBFF;

  constructor(props: any) {
    super(props);
    this.state = {
      informationsPreAssurabilite: {},
      erreurStatut: 0,
      enChargement: false,
      enChargementQuestion: false,
      stateQuestionnaireSoumis: false,
      questionStepCourant: 0,
      showBtnContinuer: true,
      modaleEnregistrerOuvert: false,
      declencheurModaleEnregistrer: "",
    };

    this.ServiceAssurabiliteBFF = new ServiceAssurabiliteBFF();
    this.handleReponseQuestion = this.handleReponseQuestion.bind(this);
    this.onClickContinuer = this.onClickContinuer.bind(this);
    this.onClickEtatModaleEnregistrer = this.onClickEtatModaleEnregistrer.bind(this);
  }

  async componentDidMount() {
    //Changer le titre de la page
    document.title = i18n.t("jampp:titre");

    let cookieParcoursAssurance = this.props.cookieParcoursAssurance;

    if (!cookieParcoursAssurance) {
      this.setState({ erreurStatut: ErreurHTTP.ErreurRequeteInvalide });
    } else if (!cookieParcoursAssurance.idSouscription || !cookieParcoursAssurance.idAssure) {
      this.setState({ erreurStatut: ErreurHTTP.ErreurRequeteInvalide });
    } else {
      this.setState({ enChargement: true });
      await this.ServiceAssurabiliteBFF.effectuerAppelPostDemarrerPreAssurabilite(
        cookieParcoursAssurance.idSouscription,
        cookieParcoursAssurance.idAssure,
        cookieParcoursAssurance.langue,
        this.succesAppelPostDemarrerPreAssurabilite,
        this.erreurAppelBFF
      );
    }
  }

  succesAppelPostDemarrerPreAssurabilite = (reponse: { data: InformationsPreAssurabilite }) => {
    this.setState(
      {
        informationsPreAssurabilite: reponse.data,
        enChargement: false,
        erreurStatut: 0,
      },
      () =>
        CybermetriesJAMPP.EnvoiePageObtenirReponse(this.creeCybermetrieParams(this.state.informationsPreAssurabilite))
    );
  };

  erreurAppelBFF = (reponse: any) => {
    let status = reponse?.response?.status;

    this.setState({ erreurStatut: status, enChargement: false, enChargementQuestion: false });
  };

  async handleReponseQuestion(idQuestion: string, reponseQuestion: string) {
    let informationsPreAssurabilite: InformationsPreAssurabilite = { ...this.state.informationsPreAssurabilite };
    let question = informationsPreAssurabilite.questions?.find((question) => question.id === idQuestion);

    if (question.reponse !== reponseQuestion) {
      question.reponse = reponseQuestion;
      this.setState({
        informationsPreAssurabilite,
        stateQuestionnaireSoumis: false,
        questionStepCourant: question.step,
        enChargementQuestion: true,
      });

      await this.ServiceAssurabiliteBFF.effectuerAppelPostRepondreQuestionPreassurabilite(
        informationsPreAssurabilite.idAssurabilite,
        question.id,
        question.step,
        question.reponse,
        this.succesAppelPostRepondrePreAssurabilite,
        this.erreurAppelBFF
      );
    }
  }

  succesAppelPostRepondrePreAssurabilite = (reponse: { data: ReponsePreAssurabilite }) => {
    let informationsPreAssurabilite: InformationsPreAssurabilite = this.state.informationsPreAssurabilite;
    informationsPreAssurabilite.decision = reponse.data.decision;
    informationsPreAssurabilite.exigence = reponse.data.exigence;
    const stepQuestionRepondu: number = this.state.questionStepCourant;

    //Retirer les questions dont le step est supérieur à la question que l'utilisateur vient de répondre
    for (let i = informationsPreAssurabilite.questions.length - 1; i >= 0; i--) {
      if (informationsPreAssurabilite.questions[i].step > stepQuestionRepondu) {
        informationsPreAssurabilite.questions.pop();
      }
    }

    informationsPreAssurabilite.questions = [...informationsPreAssurabilite.questions, ...reponse.data.questions];

    this.setState({
      informationsPreAssurabilite: informationsPreAssurabilite,
      enChargement: false,
      erreurStatut: 0,
      enChargementQuestion: false,
    });
  };

  determinerErreur() {
    let url = obtenirNavigationUrl(this.props.cookieParcoursAssurance.idCallback);
    let urlParams = `${this.props.parcours}/${this.props.cookieParcoursAssurance.langue}/error?idSouscription=${this.props.cookieParcoursAssurance.idSouscription}&idAssure=${this.props.cookieParcoursAssurance.idAssure}`;
    window.location.assign(url + urlParams);
  }

  questionsToutesRepondues() {
    const questionsSansReponse = this.state.informationsPreAssurabilite.questions?.filter(
      (question) => question.reponse == null
    );
    return questionsSansReponse?.length === 0;
  }

  onClickContinuer() {
    this.setState({ stateQuestionnaireSoumis: true });
    if (this.questionsToutesRepondues()) {
      CybermetriesJAMPP.EnvoieReponseDemande(
        this.creeCybermetrieParams(this.state.informationsPreAssurabilite),
        this.state.informationsPreAssurabilite.decision
      );
      let url = obtenirNavigationUrl(this.props.cookieParcoursAssurance.idCallback);
      let urlParams = `${this.props.parcours}/${this.props.cookieParcoursAssurance.langue}/next?idSouscription=${this.props.cookieParcoursAssurance.idSouscription}&idAssure=${this.props.cookieParcoursAssurance.idAssure}`;
      window.location.assign(url + urlParams);
    }
  }

  onClickEtatModaleEnregistrer(etat: boolean, declencheur: string) {
    this.setState({ modaleEnregistrerOuvert: etat });
    if (etat) {
      this.setState({ declencheurModaleEnregistrer: declencheur });
    } else {
      let elementRetour: any;
      if (this.state.declencheurModaleEnregistrer === "btn-evenement-retour") {
        //Si le lien à focuser est le bouton de l'entete, on doit aller chercher l'element dans le shadowRoot
        elementRetour = document
          .getElementsByTagName("oel-header-transactionnel")[0]
          .shadowRoot.getElementById("btn-evenement-retour");
      } else {
        elementRetour = document.getElementById("btn-enregistrer").getElementsByClassName("button")[0];
      }
      //Bug de la modale DSD, elle ajoute un tabIndex = -1 lors de la fermeture sur l'element qui la ouvert. Alors on enleve l'attribut
      setTimeout(
        () => document.getElementById("btn-enregistrer").querySelector("button")?.removeAttribute("tabIndex"),
        1
      );
      setTimeout(() => document.getElementsByTagName("oel-header-transactionnel")[0]?.removeAttribute("tabIndex"), 1);
      setTimeout(() => elementRetour?.focus(), 1);
    }
  }

  creeCybermetrieParams(informationsPreassurabilite: InformationsPreAssurabilite) {
    return {
      noDemande: informationsPreassurabilite.noDemandeFinancement,
      parcours: this.props.parcours,
    } as CybermetrieParamsJAMPP;
  }

  render() {
    return (
      <gabarit-global configuration={CONFIG_GABARIT_GLOBAL.config}>
        {this.state.erreurStatut !== 0 && this.determinerErreur()}
        <main>
          {this.state.enChargementQuestion && <ModalLoadingJAMPP />}
          {!this.state.enChargementQuestion && <SpanAlerte>{i18n.t("sr-only-chargement-termine")}</SpanAlerte>}
          <CybermetrieJAMPPContext.Provider value={this.creeCybermetrieParams(this.state.informationsPreAssurabilite)}>
            <div className="menu-etape-header">
              <StepIndicator />
            </div>
            <div className="content">
              <MenuEtape />
              <div className="texte-haut-questionnaire">
                <TexteHautQuestionnaire parcours={this.props.parcours} />
                {this.state.stateQuestionnaireSoumis && (
                  <QuestionsSommaireErreurs
                    parcours={this.props.parcours}
                    questions={this.state.informationsPreAssurabilite.questions}
                  />
                )}
                <div className="questionnaire">
                  <QuestionnaireContext.Provider value={{ estSoumis: this.state.stateQuestionnaireSoumis }}>
                    <Questionnaire
                      parcours={this.props.parcours}
                      informationsPreAssurabilite={this.state.informationsPreAssurabilite}
                      enChargement={this.state.enChargement}
                      handleReponseQuestion={this.handleReponseQuestion}
                    />
                    <Boutons
                      onClickContinuer={this.onClickContinuer}
                      onClickEtatModaleEnregistrer={this.onClickEtatModaleEnregistrer}
                      modaleEnregistrerOuvert={this.state.modaleEnregistrerOuvert}
                      cookieParcoursAssurance={this.props.cookieParcoursAssurance}
                      showBtnContinuer={this.state.showBtnContinuer}
                      informationsPreAssurabilite={this.state.informationsPreAssurabilite}
                      parcours={this.props.parcours}
                    />
                  </QuestionnaireContext.Provider>
                </div>
              </div>
              {this.props.parcours === TYPE_PARCOURS.AUTONOME ? (
                <ClickToCall
                  id={"click-to-call-pre-assurabilite"}
                  transit={this.state.informationsPreAssurabilite.transit}
                  noDemande={this.state.informationsPreAssurabilite.noDemande}
                />
              ) : (
                ""
              )}
            </div>
          </CybermetrieJAMPPContext.Provider>
        </main>
      </gabarit-global>
    );
  }
}

export default Content;
