import i18n from "../../resources/I18n";
import Configuration from "../../Configuration";
import { DsdAlerte } from "../DSD/DsdAlerte";
import { DsdContactBloc } from "../DSD/DsdContactBloc";
import "./erreurs.css";
import { DsdVSpacer } from "../DSD/DsdVSpacer";
import { DsdGrid } from "../DSD/DsdGrid";

function ErreurTechnique() {
  return (
    <main>
      <DsdGrid>
        <h1 dangerouslySetInnerHTML={{ __html: i18n.t("assurabilite:titre-barre-progression") }} />
        <DsdAlerte datatestid="dsd-alerte-erreur" id="alerte-erreur" type="error" dynamic={true}>
          {i18n.t("assurabilite:texte-erreur-technique")}
        </DsdAlerte>
        <DsdContactBloc />
        <dsd-link-action data-testid="dsd-link-action" type="return">
          <a id="btn-retour-sommaire" data-testid="btn-retour-sommaire" href={Configuration.obtenirConfigurations().urlSommaire}>
            {i18n.t("assurabilite:btn-retour-sommaire")}
          </a>
        </dsd-link-action>
        <DsdVSpacer espacement="6" />
      </DsdGrid>
    </main>
  );
}

export default ErreurTechnique;
