import i18n from "../../resources/I18n";
import "./header.css";
import { DsdVSpacer } from "../DSD/DsdVSpacer";
import Configuration from "../../Configuration";

function HeaderPageErreur() {
  return (
    <>
      <div data-testid="header-page-erreur" className="header-container">
        <oel-header-transactionnel
          data-testid="oel-header-transactionnel"
          libelle-bouton-retour={i18n.t("assurabilite:entete-texte-retour")}
          comportement-bouton-retour="retour"
          base-rem="base-16"
          type-container="dsd-standard"
          id-contenu-principal={"#alerte-erreur"}
          taille-texte-normale="100%"
          taille-texte-moyenne="150%"
          taille-texte-large="200%"
          langue={i18n.language}
          class="hydrated"
          url-retour={Configuration.obtenirConfigurations().urlSommaire}
          url-retour-non-authentifie={Configuration.obtenirConfigurations().urlSommaire}
        />
      </div>
      <DsdVSpacer espacement="5" />
    </>
  );
}

export default HeaderPageErreur;
