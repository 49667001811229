import React, { useContext } from "react";
import { CybermetrieParams } from "../../models/CybermetrieParamsAPRHN";

const defaultContext = new CybermetrieParams();

const CybermetrieContext = React.createContext(defaultContext);

export const useCybermetrieContext = () => useContext(CybermetrieContext);

export { CybermetrieContext };
