import React, { ReactNode } from "react";

interface IDsdButtonProps {
  id: string;
  href?: string;
  variant?: string;
  iconName?: string;
  iconPosition?: string;
  children?: ReactNode;
  onClick?: any;
  datatestid?: string;
}

export const DsdButton: React.FC<IDsdButtonProps> = ({
  id,
  href,
  variant,
  iconName,
  iconPosition,
  children,
  onClick,
  datatestid,
}) => {
  return (
    <dsd-button
      id={id}
      href={href}
      variant={variant}
      full-width={"xs"}
      icon-name={iconName}
      icon-position={iconPosition}
      onClick={onClick}
      data-testid={datatestid}
    >
      {children}
    </dsd-button>
  );
};
