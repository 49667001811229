import React, { ReactNode } from "react";

interface IDsdRowProps {
  id?: string;
  children: ReactNode;
  datatestid?: string
}

export const DsdRow: React.FC<IDsdRowProps> = ({ datatestid, id, children }) => {
  return (
    <dsd-grid-row data-testid={datatestid} id={id}>
      {children}
    </dsd-grid-row>
  );
};
