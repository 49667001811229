import i18n from "../../resources/I18n";
import * as TYPE_PARCOURS from "../../constants/typeParcours";

interface ITexteHautQuestionnaireProps {
  parcours: string;
}

function ObtenirTexteIntroduction(parcoursActuelle: string) {
  if (parcoursActuelle === TYPE_PARCOURS.ASSISTE) {
    return (
      <div className="texte">
        <p
          data-testid="texte-intro-assiste"
          dangerouslySetInnerHTML={{ __html: i18n.t("jampp:texte-introduction-assiste") }}
        ></p>
      </div>
    );
  }
  if (parcoursActuelle === TYPE_PARCOURS.AUTONOME) {
    return <p data-testid="texte-intro-autonome">{i18n.t("jampp:texte-introduction-autonome")}</p>;
  }
  return <></>;
}

function TexteHautQuestionnaire(props: Readonly<ITexteHautQuestionnaireProps>) {
  return (
    <>
      <h3 id="obtenir-reponse-maintenant">{i18n.t("jampp:obtenir-reponse-maintenant")}</h3>
      {ObtenirTexteIntroduction(props.parcours)}
    </>
  );
}

export default TexteHautQuestionnaire;
