import React from "react";
import i18n from "../../resources/I18n";
import { Exigence } from "../../util/Constantes";
import { Question as modelQuestion } from "../../models/question";
import "./descriptionProtections.css";

interface IDescriptionProtectionsProps {
  exigence?: string;
  questions?: modelQuestion[] | null;
}

function determinerProtection(texte: string, id: string) {
  return (
    <p id={id} data-testid={id} className="texte-intro-aprhn" dangerouslySetInnerHTML={{ __html: i18n.t(texte) }}></p>
  );
}

function DescriptionProtections(props: Readonly<IDescriptionProtectionsProps>) {
  if (props.exigence !== Exigence.RapportAssurabilite && props.questions !== null && props?.questions?.length !== 0) {
    return determinerProtection("assurabilite:texte-verifier-votre-admissiblite", "texte-verifier-votre-admissiblite");
  }

  return <> </>;
}

export default DescriptionProtections;
