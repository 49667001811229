import i18n from "../../resources/I18n";

export function DsdContactBloc() {
    return (
        <div data-testid="dsd-contact-bloc" id="dsdcontactbloc-container">
          <h2>{i18n.t("titre-lien-contact")}</h2>
          <hr/>
            <p className="dsd-fw-bold" id="texte-obtenir-aide" data-testid="texte-obtenir-aide">{i18n.t("texte-obtenir-aide")}</p>
          <p id="texte-montreal-environs" data-testid="texte-montreal-environs" dangerouslySetInnerHTML={{ __html: i18n.t("texte-montreal-environs")}}></p>
            <a className="dsd-c-light-confirmation-5 texte-indicatif-caisses-montreal dsd-fw-bold" data-testid="numero-montreal" href={i18n.t("href-numero-montreal")} dangerouslySetInnerHTML={{ __html: i18n.t("texte-indicatif-caisses-montreal")}}></a>
            <span sr-only={i18n.t("sr-only-lien-telephonne")}></span>
            <p className="dsd-fw-bold" id="texte-numero-montreal" data-testid="texte-numero-montreal" dangerouslySetInnerHTML={{ __html: i18n.t("texte-numero-montreal")}}></p>
          <p id="texte-ailleurs-quebec" data-testid="texte-ailleurs-quebec" dangerouslySetInnerHTML={{ __html: i18n.t("texte-ailleurs-quebec")}}></p>
            <a className="dsd-c-light-confirmation-5 texte-indicatif-ailleurs-quebec dsd-fw-bold" data-testid="numero-ailleurs-quebec" href={i18n.t("href-numero-ailleurs-quebec")} dangerouslySetInnerHTML={{ __html: i18n.t("texte-indicatif-ailleurs-quebec")}}></a>
            <p className="dsd-fw-bold" id="texte-numero-ailleurs-quebec" data-testid="texte-numero-ailleurs-quebec" dangerouslySetInnerHTML={{ __html: i18n.t("texte-numero-ailleurs-quebec")}}></p>
        </div>
    )
}
  
