import i18n from "../resources/I18n";
import Configuration from "../Configuration";
import { Purify } from "./PurifyTexte";

export function obtenirURLClickToCall(noDemande: string, transit: string, raisonSortie: string, destination: string) {
  let baseUrl = "";

  if (window.innerWidth >= 480) {
    baseUrl =
      i18n.language === "en"
        ? Configuration.obtenirConfigurations().urlClickToCallDesktopEN
        : Configuration.obtenirConfigurations().urlClickToCallDesktopFR;
  } else {
    baseUrl =
      i18n.language === "en"
        ? Configuration.obtenirConfigurations().urlClickToCallMobileEN
        : Configuration.obtenirConfigurations().urlClickToCallMobileFR;
  }

  const url = encodeURIComponent(Purify(window.location.href));
  const struct = ["VPA", "DPCCCSC", "Financement"];

  return `${baseUrl}?struct=${encodeURI(JSON.stringify(struct))}&dest=${destination}&da=P&source=${url}&MVT_REFERENCE_EXTERNE=${Purify(
    noDemande
  )}&MVT_TYPE_REFERENCE_EXTERNE=Numero ID Demande&MVT_CONTEXTE=Assurance&MVT_URL=${url}&MVT_RAISON_SORTIE=${raisonSortie} ${Purify(
    transit
  )}&MVT_LANGUE=${i18n.language}`;
}
