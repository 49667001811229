import React, { ReactNode, useEffect, useRef } from "react";

interface IDsdModaleProps {
  id?: string;
  buttonPrimaryLabel: string;
  buttonSecondaryLabel: string;
  spanSlotTitle: string;
  show: boolean;
  dsdModalSecondaryClick: Function;
  dsdModalPrimaryClick: (etat: boolean) => void;
  returnFocus?: string;
  children?: ReactNode;
  dataTestId?: string;
}

export const DsdModale: React.FC<IDsdModaleProps> = ({
  id,
  show,
  buttonPrimaryLabel,
  buttonSecondaryLabel,
  spanSlotTitle,
  dsdModalSecondaryClick,
  dsdModalPrimaryClick,
  returnFocus,
  children,
  dataTestId,
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const onClickClose = (e: any) => {
      dsdModalSecondaryClick(false);
    };

    const onClickEnregistrer = (e: any) => {
      dsdModalPrimaryClick(true);
    };
    //@ts-ignore
    elementRef?.current?.addEventListener("dsdModalSecondaryClick", onClickClose);
    elementRef?.current?.addEventListener("dsdModalPrimaryClick", onClickEnregistrer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <dsd-modal
      id={id}
      data-testid={dataTestId}
      show={show}
      ref={elementRef}
      button-primary-label={buttonPrimaryLabel}
      button-secondary-label={buttonSecondaryLabel}
      dsdModalSecondaryClick={dsdModalSecondaryClick}
      dsdModalPrimaryClick={dsdModalPrimaryClick}
    >
      <span slot="title">{spanSlotTitle}</span>
      {children}
    </dsd-modal>
  );
};
