import React from "react";
import { CSSTransition } from "react-transition-group";
import { InformationsPreAssurabilite } from "../../models/informations-pre-assurabilite";
import Assure from "./Assure";
import DescriptionProtections from "./DescriptionProtections";
import Loading from "./Loading";
import "./questionnaire.css";

interface IQuestionnaireProps {
  informationsPreAssurabilite: InformationsPreAssurabilite;
  enChargement: boolean;
  idAssure: string;
  handleReponseQuestion: any;
}

function Questionnaire(props: Readonly<IQuestionnaireProps>) {
  const assures = props.informationsPreAssurabilite.assures;
  const exigence = props.informationsPreAssurabilite.exigence;
  const questions = props.informationsPreAssurabilite.questions;

  const ObtenirAssures = () => {
    const premierAssure = assures?.find((assure) => assure.id === props.idAssure);
    const deuxiemeAssure = assures?.find((assure) => assure.id !== props.idAssure);

    return (
      <>
        {premierAssure && (
          <Assure
            informations={premierAssure}
            coEmprunteur={false}
            questions={questions}
            handleReponseQuestion={props.handleReponseQuestion}
            informationsPreAssurabilite={props.informationsPreAssurabilite}
            idAssure={props.idAssure}
          />
        )}
        {deuxiemeAssure && <Assure questions={questions} informations={deuxiemeAssure} coEmprunteur={true} />}
      </>
    );
  };

  const nodeRef = React.useRef(null);

  return (
    <div data-testid="questionnaire-container" id="questionnaire-container">
      <DescriptionProtections exigence={exigence} questions={questions} />
      <CSSTransition data-testid="css-transition" nodeRef={nodeRef} in={!props.enChargement} timeout={100} classNames="questionnaire-transition">
        <div ref={nodeRef}>{props.enChargement ? <Loading /> : ObtenirAssures()}</div>
      </CSSTransition>
    </div>
  );
}

export default Questionnaire;
