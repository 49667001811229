import i18n from "../../resources/I18n";
import "./header.css";
import { useEffect } from "react";

interface IHeaderProps {
  onClickEnregistrer?: Function;
  retour: string;
  idContenuPrincipal: string;
}

function Header(props: Readonly<IHeaderProps>) {
  useEffect(() => {
    const onClicEnregistrer = () => {
      props.onClickEnregistrer(true, "btn-evenement-retour");
    };

    window.addEventListener("oel-header-transactionnel.boutonRetourCliquee", onClicEnregistrer);
    return () => {
      window.removeEventListener("oel-header-transactionnel.boutonRetourCliquee", onClicEnregistrer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <div data-testid="header" className="header-container">
        <oel-header-transactionnel
          data-testid="oel-header-transactionnel"
          libelle-bouton-retour={props.retour}
          comportement-bouton-retour="retour-evenement"
          base-rem="base-16"
          type-container="dsd-standard"
          id-contenu-principal={props.idContenuPrincipal}
          taille-texte-normale="100%"
          taille-texte-moyenne="150%"
          taille-texte-large="200%"
          langue={i18n.language}
          class="hydrated"
        />
      </div>
  );
}

export default Header;
