import { Assure } from "../../models/assure";
import i18n from "../../resources/I18n";
import { EtatDemande } from "../../util/Constantes";
import { Purify } from "../../util/PurifyTexte";

interface IInformationsAssure {
  informationAssure: Assure;
}

export function DetailsCoemprunteur(props: Readonly<IInformationsAssure>) {
  function TexteCoEmprunteur() {
    switch (props.informationAssure.etatDemande) {
      case EtatDemande.Termine:
        return i18n.t("assurabilite:texte-coemprunteur-complete");

      case EtatDemande.NonTermine:
        return i18n
          .t("assurabilite:texte-coemprunteur-non-complete")
          .replace("{prenom}", props.informationAssure.prenom)
          .replace("{nom}", props.informationAssure.nom);

      case EtatDemande.SansAssurance:
        return i18n.t("texte-coemprunteur-sans-assurance");

      case EtatDemande.NonAdmissible:
        return i18n.t("texte-coemprunteur-non-admissible");

      case EtatDemande.QuestionnaireRA:
        return i18n.t("texte-coemprunteur-questionnaire-ra");

      case EtatDemande.RefereEnSelection:
        return i18n.t("texte-coemprunteur-refere-en-selection");

      default:
        return "";
    }
  }
  return <p data-testid="texte-coemprunteur" dangerouslySetInnerHTML={{ __html: Purify(TexteCoEmprunteur()) }}></p>;
}
