import "./modalLoadingJAMPP.css";
import { DsdLoading } from "../DSD/DsdLoading";
import i18n from "../../resources/I18n";

function ModalLoadingJAMPP() {
  return (
    <dsd-backdrop>
      <dsd-container class="loading-centered full-height">
        <DsdLoading id="element-modal-loading" size="xs" />
        <span className="dsd-sr-only">{i18n.t("message-chargement")}</span>
      </dsd-container>
    </dsd-backdrop>
  );
}

export default ModalLoadingJAMPP;
