/**
 * @module FonctionUtilitaire
 */
class FonctionUtilitaire {

  static readonly dateToJJMoisAAAA = (date: Date, langue: string) => {
    const jour = date.getDate();
    const mois = date.toLocaleString(langue, { month: 'long' });
    const annee = date.getFullYear();
    return jour + " " + mois + " " + annee;
  };
}

export default FonctionUtilitaire;
