import i18n from "i18next";

const resourcesFr = require("./fr/assurabilite.json");
const resourcesEn = require("./en/assurabilite.json");

i18n.init({
  initImmediate: false,
  preload: ["fr", "en"],
  resources: {
    fr: resourcesFr,
    en: resourcesEn,
  },
  fallbackLng: "fr",
  debug: false,
  defaultNS: "assurabilite",
});

export default i18n;
