import StepIndicator from "./StepIndicator";
import "./menuetape.css";

export interface IMenuEtape {}

function MenuEtape(props: IMenuEtape) {
  return (
    <div className="divider">
      <StepIndicator />
    </div>
  );
}

export default MenuEtape;
