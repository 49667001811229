import { Question as modelQuestion } from "../../models/question";
import i18n from "../../resources/I18n";
import { Purify } from "../../util/PurifyTexte";
import { DsdRadio } from "../DSD/DsdRadio";
import { DsdRadioGroup } from "../DSD/DsdRadioGroup";
import { useQuestionnaireContext } from "../utils/QuestionnaireContext";
import "../JAMPP/question.css";

export interface IQuestionProps {
  question: modelQuestion;
  handleReponseQuestion: any;
}

function Question(props: Readonly<IQuestionProps>) {
  const question = props.question;

  const mapperRadioBouton = question?.choixReponses?.map((choix) => (
    <DsdRadio
      datatestid={question.id + choix.valeur}
      key={`Bouton-${choix.valeur}-${question.id}`}
      id={`Bouton-${choix.valeur}-${question.id}`}
      idQuestionReponse={`${question.id}`}
      handleChangeRadio={props.handleReponseQuestion}
      name={`Question-${question.id}`}
      radioValue={choix.valeur}
      checked={props.question.reponse === choix.valeur}
    >
      {choix.libelle}
    </DsdRadio>
  ));

  function VerifierErreur() {
    const { estSoumis } = useQuestionnaireContext();
    return props.question.reponse == null && estSoumis;
  }

  const mapperLibellesPuces = question?.libelles?.puces?.map((puce: string, index) => (
    <li
      className={VerifierErreur() ? "liste-erreur" : ""}
      key={question.id + puce}
      dangerouslySetInnerHTML={{ __html: Purify(puce) }}
    />
  ));
  const nombreElementPuces = question.libelles?.puces?.length;
  const texteNombreElementPuces = i18n
    .t("question-avec-puce-aria-label")
    .replace("{nombrePuces}", String(nombreElementPuces));

  return (
    <div id="container-question">
      <DsdRadioGroup id={question.id} name={`${question.id}`} error={VerifierErreur()} required={true}>
        <span slot="legend">
          <span
            data-testid="libelle-question"
            className={"libelle-question " + (VerifierErreur() ? "libelle-erreur" : "")}
            dangerouslySetInnerHTML={{ __html: Purify(question.libelles?.libelle) }}
          ></span>
          {nombreElementPuces > 0 && (
            <>
              <span className="elements-puces" aria-label={texteNombreElementPuces} />
              <ul id="liste-puces">{mapperLibellesPuces}</ul>
            </>
          )}
        </span>
        <span slot="error">{i18n.t("assurabilite:question-en-erreur")}</span>
        <div id="groupe-bouton-radio">{mapperRadioBouton}</div>
      </DsdRadioGroup>
    </div>
  );
}

export default Question;
