import React, { useContext } from "react";
import { CybermetrieParamsJAMPP } from "../../models/CybermetrieParamsJAMPP";

const defaultContext = new CybermetrieParamsJAMPP();

const CybermetrieJAMPPContext = React.createContext(defaultContext);

export const useCybermetrieJAMPPContext = () => useContext(CybermetrieJAMPPContext);

export { CybermetrieJAMPPContext };
