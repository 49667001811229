import React, { ReactNode } from "react";

interface IDsdColProps {
  id?: string;
  col?: string;
  smCol?: string;
  mdCol?: string;
  lgCol?: string;
  xlCol?: string;
  children?: ReactNode;
}

export const DsdColumn: React.FC<IDsdColProps> = ({ col, mdCol, smCol, lgCol, xlCol, id, children }) => {
  return (
    <dsd-grid-col col={col} id={id} md-col={mdCol} sm-col={smCol} lg-col={lgCol} xl-col={xlCol}>
      {children}
    </dsd-grid-col>
  );
};
