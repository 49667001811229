import HeaderPageErreur from "../components/APRHN/HeaderPageErreur";
import ErreurTechnique from "../components/APRHN/ErreurTechnique";
import Footer from "../components/APRHN/Footer";

function PageErreurTechniqueAPRHN() {
  return (
    <div data-testid="page-erreur-technique-aprhn" className="Erreur">
      <HeaderPageErreur />
      <ErreurTechnique />
      <Footer positionPageFooter="erreurs" />
    </div>
  );
}

export default PageErreurTechniqueAPRHN;
