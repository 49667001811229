import { useEffect } from "react";
import { Question } from "../../models/question";
import { DsdAlerte } from "../DSD/DsdAlerte";
import i18n from "../../resources/I18n";
import { Purify } from "../../util/PurifyTexte";
import "./questionsSommaireErreurs.css";

interface IQuestionsProps {
  questions: Question[] | null | undefined;
  parcours: string;
}

function QuestionsSommaireErreurs(props: Readonly<IQuestionsProps>) {
  useEffect(() => {
    //Force le focus sur le alerte-erreur lors du chargement initiale
    setTimeout(() => document.getElementById("alerte-erreur")?.focus(), 200);
  }, []);

  const questionsSansReponse = props.questions?.filter((question) => question.reponse == null);
  if (questionsSansReponse?.length > 0) {
    return (
      <DsdAlerte datatestid="alerte-question" id="alerte-erreur" type="error" dynamic={true} tabIndex={-1}>
        <h4 slot="title">{i18n.t("jampp:titre-modale-erreur")}</h4>
        <p>
          <span>{i18n.t("jampp:texte-erreurs-sommaire")}</span>
          <span
            className="libelle-erreur-sommaire"
            data-testid="libelle-erreur-sommaire"
            role="button"
            onClick={() => document.getElementById(`${questionsSansReponse[0].id}`)?.scrollIntoView()}
            onKeyDown={() => document.getElementById(`${questionsSansReponse[0].id}`)?.scrollIntoView()}
            dangerouslySetInnerHTML={{ __html: Purify(questionsSansReponse[0].libelles?.libelleErreur) }}
            tabIndex={0}
          ></span>
        </p>
      </DsdAlerte>
    );
  }

  return <> </>;
}

export default QuestionsSommaireErreurs;
