import i18n from "../../resources/I18n";
import "./footer.css";

interface IFooterProps {
  positionPageFooter: string;
}

function Footer(props: Readonly<IFooterProps>) {
  return (
    <div data-testid="footer" className={props.positionPageFooter === "erreurs" ? "footer-container-page-erreur" : "footer-container"}>
      <oel-footer-transactionnel data-testid="oel-footer-transactionnel" base-rem="base-16" type-container="dsd-standard" langue={i18n.language} />
    </div>
  );
}

export default Footer;
