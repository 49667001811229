import { Component } from "react";
import { Navigate } from "react-router-dom";
import { InformationsPreAssurabilite } from "../../models/informations-pre-assurabilite";
import { ReponsePreAssurabilite } from "../../models/reponse-pre-assurabilite";
import i18n from "../../resources/I18n";
import { Decision, ErreurHTTP, Exigence, Navigation } from "../../util/Constantes";
import { Cybermetries } from "../../util/CybermetrieAPRHN";
import { INavigationParcours, majCookieParcourAssurance, obtenirNavigationUrl } from "../../util/Navigation";
import BarreProgression from "./BarreProgression";
import Boutons from "./Boutons";
import ClickToCall from "./ClickToCall";
import { DsdGrid } from "../DSD/DsdGrid";
import Footer from "./Footer";
import Header from "./Header";
import ModalLoading from "./ModalLoading";
import Questionnaire from "./Questionnaire";
import QuestionsSommaireErreurs from "./QuestionsSommaireErreurs";
import SpanAlerte from "./SpanAlerte";
import { QuestionnaireContext } from "../utils/QuestionnaireContext";
import { CybermetrieContext } from "../utils/CybermetrieAPRHNContext";
import ServiceAssurabiliteBFF from "../utils/ServiceAssurabiliteBFF";
import { DsdVSpacer } from "../DSD/DsdVSpacer";
import { CybermetrieParams } from "../../models/CybermetrieParamsAPRHN";

interface IContentProps {
  cookieParcoursAssurance: INavigationParcours;
}

interface IContentState {
  informationsPreAssurabilite: InformationsPreAssurabilite;
  erreurStatut: number;
  enChargement: boolean;
  enChargementQuestion: boolean;
  stateQuestionnaireSoumis: boolean;
  questionStepCourant: number;
  modaleEnregistrerOuvert: boolean;
  modaleContinuerSAOuvert: boolean;
  declencheurModaleEnregistrer: string;
  declencheurModaleContinuerSA: string;
  showBtnContinuer: boolean;
}

/**
 * @module Content
 * @extends Component
 */
class Content extends Component<IContentProps, IContentState> {
  ServiceAssurabiliteBFF: ServiceAssurabiliteBFF;

  constructor(props: any) {
    super(props);
    this.state = {
      /** @type {InformationsPreAssurabilite} */
      informationsPreAssurabilite: {},
      erreurStatut: 0,
      enChargement: false,
      enChargementQuestion: false,
      stateQuestionnaireSoumis: false,
      questionStepCourant: 0,
      modaleEnregistrerOuvert: false,
      modaleContinuerSAOuvert: false,
      declencheurModaleEnregistrer: "",
      declencheurModaleContinuerSA: "",
      showBtnContinuer: true,
    };

    this.handleReponseQuestion = this.handleReponseQuestion.bind(this);
    this.ServiceAssurabiliteBFF = new ServiceAssurabiliteBFF();
    this.onClickContinuer = this.onClickContinuer.bind(this);
    this.onClickEtatModaleEnregistrer = this.onClickEtatModaleEnregistrer.bind(this);
    this.onClickEtatModaleContinuerSA = this.onClickEtatModaleContinuerSA.bind(this);
  }

  async componentDidMount() {
    //Changer le titre de la page
    document.title = i18n.t("assurabilite:titre");

    let cookieParcoursAssurance = this.props.cookieParcoursAssurance;

    if (!cookieParcoursAssurance || !cookieParcoursAssurance.idSouscription || !cookieParcoursAssurance.idAssure) {
      this.setState({ erreurStatut: ErreurHTTP.ErreurRequeteInvalide });
      Cybermetries.EnvoieErreurTechnique(
        ErreurHTTP.ErreurRequeteInvalide,
        this.creeCybermetrieParams(this.state.informationsPreAssurabilite)
      );
    } else {
      this.setState({ enChargement: true });
      await this.ServiceAssurabiliteBFF.effectuerAppelPostDemarrerPreAssurabilite(
        cookieParcoursAssurance.idSouscription,
        cookieParcoursAssurance.idAssure,
        cookieParcoursAssurance.langue,
        this.succesAppelPostDemarrerPreAssurabilite,
        this.erreurAppelBFF
      );
    }
  }

  succesAppelPostDemarrerPreAssurabilite = (reponse: { data: InformationsPreAssurabilite }) => {
    this.setState({
      informationsPreAssurabilite: reponse.data,
      enChargement: false,
      erreurStatut: 0,
    });
    Cybermetries.EnvoieFormulaireEtape4(this.creeCybermetrieParams(reponse.data));
    this.handleBoutonContinuer(reponse.data.exigence);
  };

  erreurAppelBFF = (reponse: any) => {
    let status = reponse?.response?.status;

    this.setState({ erreurStatut: status, enChargement: false, enChargementQuestion: false });
    Cybermetries.EnvoieErreurTechnique(status, this.creeCybermetrieParams(this.state.informationsPreAssurabilite));
  };

  async handleReponseQuestion(idQuestion: string, reponseQuestion: string) {
    let informationsPreAssurabilite: InformationsPreAssurabilite = { ...this.state.informationsPreAssurabilite };

    let question = informationsPreAssurabilite.questions?.find((question) => question.id === idQuestion);

    if (question.reponse !== reponseQuestion) {
      question.reponse = reponseQuestion;
      this.setState({
        informationsPreAssurabilite,
        stateQuestionnaireSoumis: false,
        questionStepCourant: question.step,
        enChargementQuestion: true,
      });

      await this.ServiceAssurabiliteBFF.effectuerAppelPostRepondreQuestionPreassurabilite(
        informationsPreAssurabilite.idAssurabilite,
        question.id,
        question.step,
        question.reponse,
        this.succesAppelPostRepondreQuestionPreassurabilite,
        this.erreurAppelBFF
      );
    }
  }

  handleBoutonContinuer(exigence: string) {
    if (exigence === "RA") {
      this.setState({ showBtnContinuer: false });
    } else {
      this.setState({ showBtnContinuer: true });
    }
  }

  succesAppelPostRepondreQuestionPreassurabilite = (reponse: { data: ReponsePreAssurabilite }) => {
    let informationsPreAssurabilite: InformationsPreAssurabilite = this.state.informationsPreAssurabilite;
    informationsPreAssurabilite.decision = reponse.data.decision;
    informationsPreAssurabilite.exigence = reponse.data.exigence;

    if (reponse.data.questions && reponse.data.questions.length > 0) {
      for (let i = informationsPreAssurabilite.questions.length - 1; i >= 0; i--) {
        if (informationsPreAssurabilite.questions[i].step >= reponse.data.questions[0].step) {
          informationsPreAssurabilite.questions.pop();
        }
      }

      informationsPreAssurabilite.questions = [...informationsPreAssurabilite.questions, ...reponse.data.questions];
    }
    if (reponse.data.decision === Decision.Accepte || reponse.data.exigence === Exigence.RapportAssurabilite) {
      const stepQuestionRepondu: number = this.state.questionStepCourant;

      for (let i = informationsPreAssurabilite.questions.length - 1; i >= 0; i--) {
        if (informationsPreAssurabilite.questions[i].step > stepQuestionRepondu) {
          informationsPreAssurabilite.questions.pop();
        }
      }
    }
    this.setState({
      informationsPreAssurabilite: informationsPreAssurabilite,
      enChargement: false,
      erreurStatut: 0,
      enChargementQuestion: false,
    });
    this.handleBoutonContinuer(reponse.data.exigence);
  };

  onClickContinuer() {
    this.setState({ stateQuestionnaireSoumis: true });
    Cybermetries.EnvoieQuestionsEvent(
      this.state.informationsPreAssurabilite.questions,
      this.creeCybermetrieParams(this.state.informationsPreAssurabilite)
    );
    if (this.questionToutesRepondues()) {
      majCookieParcourAssurance(Navigation.Continuer);
      window.location.assign(obtenirNavigationUrl(this.props.cookieParcoursAssurance.idCallback));
    }
  }

  onClickEtatModaleEnregistrer(etat: boolean, declencheur: string) {
    this.setState({ modaleEnregistrerOuvert: etat });
    if (etat) {
      this.setState({ declencheurModaleEnregistrer: declencheur });
    } else {
      let elementRetour: any;
      if (this.state.declencheurModaleEnregistrer === "btn-evenement-retour") {
        //Si le lien à focuser est le bouton de l'entete, on doit aller chercher l'element dans le shadowRoot
        elementRetour = document
          .getElementsByTagName("oel-header-transactionnel")[0]
          .shadowRoot.getElementById("btn-evenement-retour");
      } else {
        elementRetour = document.getElementById("btn-enregistrer").getElementsByClassName("button")[0];
      }
      //Bug de la modale DSD, elle ajoute un tabIndex = -1 lors de la fermeture sur l'element qui la ouvert. Alors on enleve l'attribut
      setTimeout(
        () => document.getElementById("btn-enregistrer").querySelector("button").removeAttribute("tabIndex"),
        1
      );
      setTimeout(() => document.getElementsByTagName("oel-header-transactionnel")[0].removeAttribute("tabIndex"), 1);
      setTimeout(() => elementRetour.focus(), 1);
    }
  }

  onClickEtatModaleContinuerSA(etat: boolean) {
    this.setState({ modaleContinuerSAOuvert: etat });
  }

  questionToutesRepondues() {
    const questionsSansReponse = this.state.informationsPreAssurabilite.questions?.filter(
      (question) => question.reponse == null
    );
    return questionsSansReponse?.length === 0;
  }

  determinerErreur() {
    if (this.state.erreurStatut >= ErreurHTTP.ErreurServeur) {
      return <Navigate to={i18n.t("assurabilite:url-erreur-fonctionnelle")} />;
    } else {
      return <Navigate to={i18n.t("assurabilite:url-erreur-technique")} />;
    }
  }

  creeCybermetrieParams(informationsPreAssurabilite: InformationsPreAssurabilite) {
    return {
      noDemande: informationsPreAssurabilite.noDemande,
      typeRenouvellement: informationsPreAssurabilite.assures?.length > 1 ? "coemprunteur" : "emprunteurUnique",
      typeAssurance: i18n.t("cybermetrie-type-assurance-" + informationsPreAssurabilite.typeAssurance),
      typeProtection: informationsPreAssurabilite.typeProtection === "1002" ? "apmat" : "ap",
    } as CybermetrieParams;
  }

  render() {
    return (
      <>
        <Header
          onClickEnregistrer={this.onClickEtatModaleEnregistrer}
          retour={i18n.t("assurabilite:entete-texte-enregistrer")}
          idContenuPrincipal="#barre-progression"
        />
        <DsdVSpacer espacement="5" />
        {this.state.erreurStatut !== 0 && this.determinerErreur()}
        <main>
          <DsdGrid>
            <CybermetrieContext.Provider value={this.creeCybermetrieParams(this.state.informationsPreAssurabilite)}>
              <BarreProgression />
              {this.state.stateQuestionnaireSoumis && (
                <QuestionsSommaireErreurs questions={this.state.informationsPreAssurabilite.questions} />
              )}
              <QuestionnaireContext.Provider value={{ estSoumis: this.state.stateQuestionnaireSoumis }}>
                <Questionnaire
                  informationsPreAssurabilite={this.state.informationsPreAssurabilite}
                  enChargement={this.state.enChargement}
                  idAssure={this.props.cookieParcoursAssurance?.idAssure}
                  handleReponseQuestion={this.handleReponseQuestion}
                />
                <Boutons
                  onClickContinuer={this.onClickContinuer}
                  onClickEtatModaleEnregistrer={this.onClickEtatModaleEnregistrer}
                  onClickEtatModaleContinuerSA={this.onClickEtatModaleContinuerSA}
                  modaleEnregistrerOuvert={this.state.modaleEnregistrerOuvert}
                  modaleContinuerSAOuvert={this.state.modaleContinuerSAOuvert}
                  cookieParcoursAssurance={this.props.cookieParcoursAssurance}
                  showBtnContinuer={this.state.showBtnContinuer}
                />
              </QuestionnaireContext.Provider>
              {this.state.enChargementQuestion && <ModalLoading />}
              {!this.state.enChargementQuestion && (
                <SpanAlerte>{String(i18n.t("sr-only-chargement-termine"))}</SpanAlerte>
              )}
              {this.state.enChargementQuestion && <SpanAlerte>{String(i18n.t("message-chargement"))}</SpanAlerte>}
              <ClickToCall
                id={"click-to-call-pre-assurabilite"}
                transit={this.state.informationsPreAssurabilite.transit}
              />
            </CybermetrieContext.Provider>
          </DsdGrid>
        </main>
        <Footer positionPageFooter="preassurabilite" />
      </>
    );
  }
}

export default Content;
