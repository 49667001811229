import { useEffect } from "react";
import { DsdLoading } from "../DSD/DsdLoading";
import "./loading.css";

function Loading() {
  useEffect(() => {
    //Force le focus sur le spinner lors du chargement initiale
    setTimeout(() => document.getElementById("element-loading")?.focus(), 1000);

    return () => {
      //Focus sur le span chargement terminé lorsque enlève le spinner
      document.getElementById("chargement-termine")?.setAttribute("aria-hidden", "false");
      document.getElementById("chargement-termine")?.focus();
    };
  }, []);

  return <DsdLoading id="element-loading" size={"md"} />;
}

export default Loading;
