import i18n from "../../resources/I18n";
import { DsdModale } from "../DSD/DsdModale";
import { Cybermetries } from "../../util/CybermetrieAPRHN";
import { CybermetrieParams } from "../../models/CybermetrieParamsAPRHN";
import { INavigationParcours, majCookieParcourAssurance, obtenirNavigationUrl } from "../../util/Navigation";
import { Navigation } from "../../util/Constantes";
import { useCybermetrieContext } from "../utils/CybermetrieAPRHNContext";
import { useEffect, useRef } from "react";

interface IModaleContinuerSA {
  modaleOuvert: boolean;
  cookieParcoursAssurance: INavigationParcours;
  onClickModaleContinuerSAFermer: Function;
}

export default function ModaleContinuerSA(props: Readonly<IModaleContinuerSA>) {
  let cybermetrieParams = useCybermetrieContext();

  const ancienneValeurCybermetrieParams = useRef(new CybermetrieParams());

  useEffect(() => {
    ancienneValeurCybermetrieParams.current = cybermetrieParams;
  }, [cybermetrieParams]);

  const onClickBoutonContinuerSA = () => {
    majCookieParcourAssurance(Navigation.ContinuerSA);
    Cybermetries.EnvoieContinuerSansAssuranceEvent(ancienneValeurCybermetrieParams.current);
    window.location.assign(obtenirNavigationUrl(props.cookieParcoursAssurance.idCallback));
  };

  return (
    <DsdModale
      id="modale-continuer-sa"
      dataTestId="modale-continuer-sa"
      show={props.modaleOuvert}
      buttonPrimaryLabel={i18n.t("assurabilite:bouton-principal-modale-continuer-sans-assurance")}
      buttonSecondaryLabel={i18n.t("assurabilite:bouton-secondaire-modale-continuer-sans-assurance")}
      dsdModalSecondaryClick={props.onClickModaleContinuerSAFermer}
      dsdModalPrimaryClick={onClickBoutonContinuerSA}
      spanSlotTitle={i18n.t("assurabilite:titre-modale-continuer-sans-assurance")}
    >
      <p>{i18n.t("assurabilite:texte-modale-continuer-sans-assurance")}</p>
    </DsdModale>
  );
}
