import React, { ReactNode, useRef } from "react";

interface IDsdRadioProps {
  id?: string;
  idQuestionReponse: string;
  name: string;
  handleChangeRadio: any;
  radioValue: string;
  checked: boolean;
  children: ReactNode;
  datatestid?: string;
}

export const DsdRadio: React.FC<IDsdRadioProps> = ({
  id,
  idQuestionReponse,
  name,
  handleChangeRadio,
  radioValue,
  checked,
  children,
  datatestid,
}) => {
  const elementRef = useRef(null);

  return (
    <dsd-radio
      ref={elementRef}
      id={id}
      name={name}
      value={radioValue}
      idQuestionReponse={idQuestionReponse}
      checked={checked}
      data-testid={datatestid}
      onClick={() => handleChangeRadio(idQuestionReponse, radioValue)}
    >
      {children}
    </dsd-radio>
  );
};
