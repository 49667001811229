import React, { useContext } from "react";

const defaultcontexte = {
    estSoumis: false,
};

const QuestionnaireContext = React.createContext(defaultcontexte);

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);

export { QuestionnaireContext };