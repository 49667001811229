import "./questionnaire.css";
import { Question } from "../../models/question";
import i18n from "../../resources/I18n";
import { DsdAlerte } from "../DSD/DsdAlerte";
import { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Purify } from "../../util/PurifyTexte";

interface IQuestionsProps {
  questions: Question[] | null | undefined;
}

function QuestionsSommaireErreurs(props: Readonly<IQuestionsProps>) {
  useEffect(() => {
    //Force le focus sur le alerte-erreur lors du chargement initiale
    setTimeout(() => document.getElementById("alerte-erreur").focus(), 200);
  }, []);

  const questionsSansReponse = props.questions?.filter((question) => question.reponse == null);
  if (questionsSansReponse?.length > 0) {
    return (
        <DsdAlerte datatestid="alerte-erreur" id="alerte-erreur" type="error" dynamic={true} tabIndex={-1}>
          <p>{i18n.t("assurabilite:texte-erreurs-sommaire")}</p>
          <ul>
            {questionsSansReponse?.map((question, index) => (
              <li key={question.id + question.step}>
                <Link
                  data-testid="alerte-link"
                  className="libelle-erreur-sommaire"
                  to={`/#${question.id}`}
                  dangerouslySetInnerHTML={{ __html: Purify(question.libelles?.libelleErreur) }}
                ></Link>
              </li>
            ))}
          </ul>
        </DsdAlerte>
    );
  }

  return <> </>;
}

export default QuestionsSommaireErreurs;
