import { useState, useEffect } from 'react';
import { AuthentificationService } from '@oel/js-authentification';
import { Environnement } from "../../util/Constantes"

import {
    getConfig,
    IMetaTag,
    MetaTagsArray,
} from './auth0Config';

export function useAuth0(): any {
    const [metaConfig, setMetaConfig] = useState<MetaTagsArray>(null);

    const changeMetaTag = (metaTag: IMetaTag) => {
        document
            .querySelector('meta[name="' + metaTag.name + '"]')
            .setAttribute('content', metaTag.content);
    };

    const getToken = async () => {
        return await AuthentificationService.getToken().then((token) => token);
    };

    const initialiser = (env: Environnement, estParcourAssite: boolean) => {
        if(!metaConfig){
            const config = getConfig(env, estParcourAssite);
            // eslint-disable-next-line
            setMetaConfig(config);
        }
    };

    useEffect(() => {
        // Change le "content" des meta-tags (index.html)
        const changeMetaTags = (metaTags: MetaTagsArray) => {
            metaTags.forEach((meta) => {
                changeMetaTag(meta);
            });
        };

        if (metaConfig) {
            changeMetaTags(metaConfig);
            AuthentificationService.initialiser();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaConfig]);

    return {
        initialiser,
        getToken,
    };
}