/**
 * 
 * @export
 * @class CybermetrieParams
 */
export class CybermetrieParams {
    /**
     * No Demande
     * @type {string}
     * @memberof CybermetrieParams
     */
    noDemande?: string;
    /**
     * Type Renouvellement 1 Assuré -> emprunteurUnique ou plus de 1 Assuré -> coemprunteur
     * @type {string}
     * @memberof CybermetrieParams
     */
    typeRenouvellement?: string;
    /**
     * Type D'Assurance VIE ou Complet ou Intermediaire
     * @type {string}
     * @memberof CybermetrieParams
     */
    typeAssurance?: string;
    /**
     * Type Protection 1002 -> P1 ou 1001 - P3
     * @type {string}
     * @memberof CybermetrieParams
     */
    typeProtection?: string;      
}


