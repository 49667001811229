import React, { ReactNode } from "react";

interface IDsdLoadingProps {
  id?: string;
  size: string;
  children?: ReactNode;
}

export const DsdLoading: React.FC<IDsdLoadingProps> = ({ size, id, children }) => {
  return (
    <dsd-loading data-testid="dsd-loading" size={size} id={id}>
      {children}
    </dsd-loading>
  );
};
