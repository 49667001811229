import Configuration from "../Configuration";
import Cookies from "universal-cookie";
import { Cookie } from "./Constantes";

export interface INavigationParcours {
  langue: string;
  idAssure?: string;
  idSouscription?: string;
  idCallback?: string;
  statutNavigation?: string;
}

export interface INavigationConfig {
  idCallback?: string;
}

export function obtenirNavigationUrl(idCallback: string) {
  let configurationCallBackInfos = Configuration.obtenirConfigurations().consommateurCallBackInfos;
  let urlCallBack = "";

  configurationCallBackInfos.forEach(function (configCallBackInfos: any) {
    if (configCallBackInfos.idCallback === idCallback) {
      urlCallBack = configCallBackInfos.url;
      return urlCallBack;
    }
  });

  return urlCallBack;
}

export function majCookieParcourAssurance(nomStatutNavigation: string) {
  const cookie = new Cookies();

  if (nomStatutNavigation) {
    let parcoursAssuranceCookie: INavigationParcours = cookie.get(Cookie.ParcoursAssurance);
    parcoursAssuranceCookie.statutNavigation = nomStatutNavigation;

    cookie.set(Cookie.ParcoursAssurance, parcoursAssuranceCookie, { domain: ".desjardins.com" });
  }
}

export function obtenirParamsNavigationURL(): INavigationParcours {
  const params = new URLSearchParams(window.location.search);
  const navigationParams: INavigationParcours = {
    idSouscription: params.get("idSouscription"),
    idAssure: params.get("idAssure"),
    langue: params.get("langue"),
    idCallback: params.get("idCallback"),
  };
  return navigationParams;
}
