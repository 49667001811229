import { ReactNode } from "react";

interface ISpanAlerte {
  children?: ReactNode;
}

export default function SpanAlerte(prop: Readonly<ISpanAlerte>) {
  return (
    <span data-testid="span-alerte" role={"alert"} className="dsd-sr-only">
      {prop.children}
    </span>
  );
}
