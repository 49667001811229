import i18n from "../../resources/I18n";
import { DsdAlerte } from "../DSD/DsdAlerte";
import "./alerteinformation.css";

interface IAlerteInformationProps {
  messageAlerteInformation: string;
  typeAlerte: string;
}

function AlerteInformation(props: Readonly<IAlerteInformationProps>) {
  return (
    <DsdAlerte
      type={props.typeAlerte}
      id="message-alerte-information"
      datatestid="message-alerte-information"
      fullWidth={true}
      dynamic={false}
      tabIndex={0}
    >
      <h4 slot="title">{i18n.t("jampp:titre-modale-information")}</h4>
      <p>
        <span dangerouslySetInnerHTML={{ __html: props.messageAlerteInformation }}></span>
      </p>
    </DsdAlerte>
  );
}

export default AlerteInformation;
