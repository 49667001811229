import "./modalLoading.css";
import { DsdLoading } from "../DSD/DsdLoading";
import i18n from "../../resources/I18n";

function ModalLoading() {
  return (
    <dsd-backdrop>
      <dsd-container class="loading-centered full-height">
        <DsdLoading id="element-modal-loading" size="xs">
          <span>{i18n.t("assurabilite:message-chargement")}</span>
        </DsdLoading>
      </dsd-container>
    </dsd-backdrop>
  );
}

export default ModalLoading;
