import i18n from "../../resources/I18n";
import "./instructionQuestionnaire.css";

function InstructionQuestionnaire() {
  return (
      <dsd-tag>
        <p className="texte-instruction" dangerouslySetInnerHTML={{ __html: i18n.t("jampp:instruction-questionnaire") }} />
      </dsd-tag>
  );
}

export default InstructionQuestionnaire;
