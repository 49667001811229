import i18n from "../../resources/I18n";
import "./ClickToCall.css";
import { obtenirURLClickToCall } from "../../util/ClickToCall";
import { useCybermetrieContext } from "../utils/CybermetrieAPRHNContext";
import { CybermetrieParams } from "../../models/CybermetrieParamsAPRHN";
import { useEffect, useRef } from "react";
import { Cybermetries } from "../../util/CybermetrieAPRHN";

interface IClickToCall {
  transit?: string;
  id?: string;
}

export default function ClickToCall(props: Readonly<IClickToCall>) {
  let cybermetrieParams = useCybermetrieContext();

  const ancienneValeurCybermetrieParams = useRef(new CybermetrieParams());

  useEffect(() => {
    ancienneValeurCybermetrieParams.current = cybermetrieParams;
  }, [cybermetrieParams]);

  return (
    <div id={props.id}>
      <h2 id="titre-click-to-call">{i18n.t("assurabilite:click-to-call-titre")}</h2> <hr data-testid="ligne-noire" />
      <p data-testid="click-to-call-texte">{i18n.t("assurabilite:click-to-call-texte")}</p>
      <dsd-link-action
        onClick={() => Cybermetries.EnvoieParlerConseiller(ancienneValeurCybermetrieParams.current)}
        data-testid="dsd-link-action"
        type="external"
      >
        <a
          data-testid="clic-appeler-conseiller"
          href={obtenirURLClickToCall(
            ancienneValeurCybermetrieParams.current.noDemande,
            props.transit,
            "Renouvellement Hypothecaire Numerique",
            "FIN_RenouvHyp"
          )}
          target="_blank"
          rel="noreferrer"
        >
          {i18n.t("assurabilite:click-to-call-texte-lien")}
          <span className="dsd-sr-only">&nbsp;{i18n.t("sr-only-ouverture-nouvel-onglet")}</span>
        </a>
      </dsd-link-action>
    </div>
  );
}
