export enum TypeProtection {
  AssurancePretVie = "1001",
  AssurancePretMargeAtout = "1002",
}

export enum ErreurHTTP {
  ErreurRequeteInvalide = 400,
  ErreurServeur = 500,
}

export enum EtatDemande {
  NonTermine = "1",
  Termine = "2",
  SansAssurance = "3",
  NonAdmissible = "4",
  QuestionnaireRA = "5",
  RefereEnSelection = "6",
}

export enum Exigence {
  RapportAssurabilite = "RA",
}

export enum Decision {
  Accepte = "ACC",
}

export enum Navigation {
  Continuer = "NEXT",
  ContinuerSA = "SKIP",
  Precedent = "BACK",
}

export enum Cookie {
  ParcoursAssurance = "parcours_assurance",
  Config = "config",
}

export enum Environnement {
  LOCAL = "local",
  DEV = "dev",
  CERTIF = "certification",
  PREPROD = "preprod",
  PROD = "prod",
}
export interface IEnvironnement {
  local: string;
  dev: string;
  certification: string;
  integre: string;
  preprod: string;
  prod: string;
}
