import React, { ReactNode } from "react";

interface IDsdVSpacerProps {
  id?: string;
  children?: ReactNode;
  espacement: string
}

export const DsdVSpacer: React.FC<IDsdVSpacerProps> = ({ id, espacement, children }) => {
  return (
    //@ts-ignore
    <dsd-vspacer id={id} all={espacement}>
      {children}
      {/*@ts-ignore*/}
    </dsd-vspacer>
  );
};
