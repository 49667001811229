import "./questionnaire.css";
import { InformationsPreAssurabilite } from "../../models";
import React from "react";
import { CSSTransition } from "react-transition-group";
import Loading from "../APRHN/Loading";
import Assure from "./Assure";

interface IQuestionnaireProps {
  parcours: string;
  informationsPreAssurabilite: InformationsPreAssurabilite;
  enChargement: boolean;
  handleReponseQuestion: any;
}

function Questionnaire(props: Readonly<IQuestionnaireProps>) {
  const questions = props.informationsPreAssurabilite.questions;

  const nodeRef = React.useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={!props.enChargement} timeout={100} classNames="questionnaire-transition">
      <div ref={nodeRef}>
        {props.enChargement ? (
          <Loading />
        ) : (
          <Assure parcours={props.parcours} questions={questions} handleReponseQuestion={props.handleReponseQuestion}  informationsPreAssurabilite={props.informationsPreAssurabilite}/>
        )}
      </div>
    </CSSTransition>
  );
}

export default Questionnaire;
