import i18n from "../../resources/I18n";
import { DsdProgressBar } from "../DSD/DsdProgressBar";
import "./barreProgression.css";

function BarreProgression() {
  return (
    <DsdProgressBar datatestid="barre-progression" id="barre-progression" percentage="60" currentStep="4" steps="8">
      <span
        id="titre-barre-progression"
        slot="title"
        dangerouslySetInnerHTML={{ __html: i18n.t("assurabilite:titre-barre-progression") }}
      ></span>
      <span slot="subtitle">{i18n.t("assurabilite:soustitre-barre-progression")}</span>
    </DsdProgressBar>
  );
}

export default BarreProgression;
