import React, { ReactNode } from "react";

interface IDsdRowProps {
  id?: string;
  children?: ReactNode;
}

export const DsdGrid: React.FC<IDsdRowProps> = ({ id, children }) => {
  return (
    <dsd-grid data-testid="dsd-grid" id={id}>
      {children}
    </dsd-grid>
  );
};
