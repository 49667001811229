import React, { ReactNode } from "react";

interface IDsdRadioGroupProps {
  id?: string;
  name: string;
  error: boolean;
  required: boolean;
  children: ReactNode;
  datatestid?: string
}

export const DsdRadioGroup: React.FC<IDsdRadioGroupProps> = ({ id, name, error, required, children, datatestid }) => {
  return (
    <dsd-radio-group id={id} name={name} error={error} required={required} data-testid={datatestid}>
      {children}
    </dsd-radio-group>
  );
};
